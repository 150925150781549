import { Button } from "@mui/material";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import {animateScroll as scroll } from "react-scroll";
import { Link as Li } from "react-router-dom";
import LogoAix from '../Assets/Images/logo.png'
import whiteAix from '../Assets/Images/white.png'

function CareerHeader() {
  const [active, setActive] = useState("careernav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [navbar, setNavbar]=useState(false)

  const navToggle = () => {
    if (active === "careernav__menu") {
      setActive("careernav__menu nav__active");
    } else {
      setActive("careernav__menu");
    }

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else {
      setIcon("nav__toggler");
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    navToggle(); // Close the menu when scrolling to top
  };


  const changeBackground= ()=>{
    if(window.scrollY >= '12'){
      setNavbar(true)

    }else{
      setNavbar(false)
    }
  }
window.addEventListener('scroll', changeBackground)

  return (
   <div style={{width:'100%', display:'flex', justifyContent:'center', alignItems:'center'}}>

      <nav className={navbar ? 'nav active':'nav'}>
      <div className={navbar ? "nav__brand active1" : "nav__brand"}>
  <a href="/" onClick={scrollToTop}>
  {navbar ? (
          <img src={LogoAix} alt="logo2" height="35%" width="35%" />
        ) : (
          <img src={whiteAix} alt="logo1" height="35%" width="35%" />
        )}


  </a>
</div>

      <ul className={active}>
        <li className={navbar ? 'nav__item active1':'nav__item'}>
        <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/'

          >
    Home
  </Li>
        </li>


        <li className={navbar ? 'nav__item active1':'nav__item'}>
          <Link
            style={{ cursor: "pointer" }}
            activeClass="active"
            to="/"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav__link"
            onClick={navToggle}
          >
            Consulting Services
          </Link>
        </li>

        {/* <li className={navbar ? 'nav__item active1':'nav__item'}>
          <Link
            style={{ cursor: "pointer" }}
            activeClass="active"
            to="/"
            spy={true}
            smooth={true}
            offset={-70}
            duration={500}
            className="nav__link"
            onClick={navToggle}
          >
            Our Work
          </Link>
        </li> */}

         <li className={navbar ? "nav__item active1" : "nav__item"}>
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/career'

          >
    Career
  </Li>
</li>
          <li className={navbar ? "nav__item active1" : "nav__item"}>
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/developing'

          >
    Solutions
  </Li>
          </li>


          <Button
  variant="contained"

  sx={{
    cursor: "pointer",
    borderRadius: "25px",
    fontSize: "1.1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    background: "linear-gradient( 150deg , #40b298 , #261141)",
    // "&:hover": {
    //   backgroundColor: "#40b298",
    //   color: "white",
    // },
    "@media(max-width:480px)": {
      backgroundColor: "#40b298",
    },
    transition: "transform 0.5s ease", // Added transition for smooth movement
    "&:hover": {
      transform: "translateY(-1px) scale(1.05)", // Move up and slightly increase size on hover

    },
  }}

>
  <Link to="contactSection" spy={true} smooth={true} offset={-70} duration={500}
   onClick={navToggle}
  >
    Contact us
  </Link>
</Button>



      </ul>
      <div onClick={navToggle} className={icon}>
        <div className="line1"></div>
        <div className="line2"></div>
        <div className="line3"></div>
      </div>
    </nav>
    </div>
  );
}

export default CareerHeader;

import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import Team from "../../Assets/Images/team.jpg";

const WhyJoinUs = () => {
  return (
    <>
      <Box
        sx={{


          minHeight: "34rem",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "5rem",

          "@media(max-width:480px)": {
            paddingBottom: "2rem",
            minHeight: "46rem",
          },
          "@media(min-width:481px) and (max-width:768px)": {
            minHeight: "40rem",
            paddingBottom:'8rem'
          },
        }}
      >
        <Box
          sx={{
            width: "100%",
            paddingTop: "3rem",
            paddingBottom: "3rem",
            "@media(max-width:480px)": {
              paddingBottom: "1rem",
            },
          }}
        >
          <Typography
            variant="h2"
            sx={{
              fontSize: "1.8rem",
              color: "#261141",
              fontWeight: "600",
              "@media(max-width:480px)": {
                fontSize: "1.2rem",
              },

              "@media(min-width:481px) and (max-width:768px)": {
                fontSize: "1.5rem",

              },
            }}
            className="line"
          >
            <span style={{color:'#40b298'}}>Why </span> Join AIX?
          </Typography>
        </Box>
        <Grid container>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box
              sx={{
                width: "80%",
                "@media(max-width:480px)": {
                  marginBottom: "3rem",
                  width: "100%",
                },

                "@media(min-width:481px) and (max-width:768px)": {
                 paddingBottom:'4rem'
                },


              }}
            >
              <Typography
                sx={{
                  fontSize: "1.5rem",
                  marginBottom: "2rem",
                  fontWeight: "500",
                  color: "#261141",
                  "@media(max-width:480px)": {
                    fontSize: "1rem",
                    marginBottom: "1rem",
                  },

                "@media(min-width:481px) and (max-width:768px)": {
                  fontSize:'1.4rem'
                },
                "@media(min-width:769px) and (max-width:1024px)": {
                  fontSize:'1.2rem'
                },

                }}
              >
                Join us as we help you build your career, it’s not just a job.
              </Typography>
              <Typography
              sx={{
                color:'#595959',
                "@media(max-width:480px)": {
                    fontSize: "0.9rem",

                  },
                "@media(min-width:481px) and (max-width:768px)": {
                  fontSize:'1.2rem'
                },

                "@media(min-width:769px) and (max-width:1024px)": {
                  fontSize:'1rem'
                },

              }}

              >
                We bring the revolutionary power of modern-day technologies to
                businesses and people worldwide. With top 1% industry talent,
                innovators, and forward-looking people, AIX is a people’s
                first company known for its talent as much as it is for its
                solutions. Experience the feel-good factor and the true value of
                work-life balance by working with us.
              </Typography>
              <Button variant='outlined'  sx={{
  marginTop:'2rem',
    color: 'white',
   outline:'none',

color:'#261141',
    transition: "transform 0.5s ease", // Added transition for smooth movement
    "&:hover": {
      transform: "translateY(-1px) scale(1.05)", // Move up and slightly increase size on hover
backgroundColor:'#40b298',
borderColor:'#40b298',
color:'white',
    },
    cursor: 'pointer',
    fontSize: '1rem',
paddingTop:'0.5rem',
paddingBottom:'0.5rem',
borderRadius:'25px',


  }}>See More &rarr; </Button>
            </Box>
          </Grid>
          <Grid item lg={6} md={6} sm={12} xs={12}>
            <Box sx={{

'@media(min-width:481px) and (max-width:768px)':{
  paddingTop:'2rem'
},
'@media(min-width:769px) and (max-width:1024px)':{
  paddingTop:'2rem'
}

            }}>
              <div className="afterglowcard">
                <img src={Team} height="100%" width={"100%"} alt="Team" />
              </div>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default WhyJoinUs;

import { Box, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ServicesCard from "../../Components/ServicesCard";
import { AIconsulting, analyticalconsultingSVG, consultingSVG, webconsultingIcon } from "../../SVGutilities/SvgCodes";

const OurServices = () => {
  useEffect(() => {
    AOS.init({
      once: true, // Animation occurs only once
    });
  }, []);

  const iconStyle = {
    fill: "currentColor", // Inherits the text color
    width: "60px",
    height: "60px",
  };

  const consulting =
    "AIX engage in expert-advising schemes to create, sustain, or execute integrated projects and services.The goal is to help clients attain their objectives, execute projects, and improv performance.";
    const webconsulting =
    "Web development consulting allows you to get professional advice on the architecture, design, and development of your web software.";

    const analyticalconsulting =
    "As a data analytics consulting firm, we help clients unlock decision intelligence and evolve into an insight-driven organization through Business Intelligence (BI) systems.";
const AI="We assess the technological maturity and readiness of a business for AI-driven automation, and follow through all the stages of adopting artificial intelligence in your business operations."


  return (
    <>
      <Box
        data-aos="fade-up"
        data-aos-duration="1500"
        id="services"
        sx={{
          width: "100%",
          paddingTop: "3rem",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "3rem",
        }}
      >
        <Typography
          variant="h1"
          sx={{
            fontWeight: "600",
            fontSize: "1.8rem",
            color: "#261141",
            "@media(max-width:480px)": {
              fontSize: "1.2rem",
            },
  
          }}
          className="line"
        >
          <span style={{ color: "#40b298" }}>What </span>
          We Do
        </Typography>
        <Typography
          sx={{
            color: "#595959",
            fontSize: "1.2rem",
            paddingTop: "0.5rem",
            "@media(max-width:480px)": { fontSize: "1rem" },
          }}
        >
          Our services are defined by quality and commitment.
        </Typography>
      </Box>

      <Box
        sx={{
          minHeight: "30rem",
          width: "100%",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "5rem",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid container spacing={2}>
          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            data-aos="fade-up-right"
            data-aos-duration="1500"
          >
            <ServicesCard
              heading="Consulting"
              paragraph={consulting}
              icon={
                <div
                  dangerouslySetInnerHTML={{ __html: consultingSVG }}
                  style={iconStyle}
                />
              }
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            data-aos="fade-up-right"
            data-aos-duration="1500"
          >

<ServicesCard
              heading="Web development consulting"
              paragraph={webconsulting}
              icon={
                <div
                  dangerouslySetInnerHTML={{ __html: webconsultingIcon }}
                  style={iconStyle}
                />
              }
            />


          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            data-aos="fade-up-left"
            data-aos-duration="1500"
          >

            <ServicesCard
              heading="Analytical Consulting"
              paragraph={analyticalconsulting}
              icon={
                <div
                  dangerouslySetInnerHTML={{ __html: analyticalconsultingSVG }}
                  style={iconStyle}
                />
              }
            />

          </Grid>

          <Grid
            item
            xs={12}
            sm={6}
            md={6}
            lg={3}
            data-aos="fade-up-left"
            data-aos-duration="1500"
          >


<ServicesCard
              heading="AI Consulting"
              paragraph={AI}
              icon={
                <div
                  dangerouslySetInnerHTML={{ __html: AIconsulting }}
                  style={iconStyle}
                />
              }
            />


          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default OurServices;

import React from 'react'
import {Box, Typography} from '@mui/material'
import CareerHeroSectionImage from '../Assets/Images/careerbg.jpg'

const CareerHeroSection = () => {
  return (
    <>
        <Box sx={{backgroundImage:`linear-gradient(to bottom, rgba(0, 0, 0, 0.8) 30.2%, rgb(0,0, 0, 0.8) 90.9%),url(${CareerHeroSectionImage})`, backgroundPosition:'center', backgroundSize:'cover',  backgroundRepeat:'none', height:'100vh', width:'100%', display:'flex', color:'white', justifyContent:'center', alignItems:'center', flexDirection:'column'}}>

        <Typography
         
          className="workline"
              sx={{
                fontSize: "1rem",
                "@media(max-width:480px)": {
                  fontSize: "1rem",
                },
                "@media(min-width:4810px) and (max-width:900px)": {
                  fontSize: "1.2rem",
                },
              }}
            >
              AIX Consulting


            </Typography>
            <br/>
<Typography variant='h3' fontSize={'2.5rem'} fontWeight={'500'}
sx={{
  '@media(max-width:480px)':{
fontSize:'2rem'

}

}}

>Join Our Team</Typography>
<Typography>Innovate and grow with AIX</Typography>


        </Box>
    </>
  )
}

export default CareerHeroSection
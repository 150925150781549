import { Button } from "@mui/material";
import React, { useState, useEffect } from "react";
import { Link, animateScroll as scroll } from "react-scroll";
import { Link as Li } from "react-router-dom";
import LogoAix from '../Assets/Images/logo.png'
import whiteAix from '../Assets/Images/white.png'



function Header() {
  const [active, setActive] = useState("nav__menu");
  const [icon, setIcon] = useState("nav__toggler");
  const [navbar, setNavbar] = useState(false);

  const navToggle = () => {
    if (active === "nav__menu") {
      setActive("nav__menu nav__active");
    } else {
      setActive("nav__menu");
    }

    // Icon Toggler
    if (icon === "nav__toggler") {
      setIcon("nav__toggler toggle");
    } else {
      setIcon("nav__toggler");
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    navToggle(); // Close the menu when scrolling to top
  };

  const changeBackground = () => {
    if (window.scrollY >= "12") {
      setNavbar(true);
    } else {
      setNavbar(false);
    }
  };
  window.addEventListener("scroll", changeBackground);




  const [isMobile, setIsMobile] = useState(false);

  const handleScroll = () => {
    // Your scroll logic here
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768); // Adjust the breakpoint as needed
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleResize);

    // Initial check on mount
    handleResize();

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <>
      <nav className={navbar ? "nav active" : "nav"}>
      <div className={navbar ? 'nav__brand active1' : 'nav__brand'}>
  <a href="/" onClick={scrollToTop}>
    {isMobile ? (
      <img src={whiteAix} alt="logo1" height="30%" width="30%" />
    ) : (
      navbar ? (
        <img src={LogoAix} alt="logo2" height="35%" width="35%" />
      ) : (
        <img src={whiteAix} alt="logo1" height="35%" width="35%" />
      )
    )}
  </a>
</div>


        <ul className={active}>
          <li className={navbar ? "nav__item active1" : "nav__item"}>
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/'

          >
    Home
  </Li>
          </li>
          <li className={navbar ? "nav__item active1" : "nav__item"}>
            <Link
              style={{ cursor: "pointer" }}
              activeClass="active"
              to="services"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav__link"
              onClick={navToggle} // Close the menu when a link is clicked
            >
             Consulting Services
            </Link>
          </li>


          {/* <li className={navbar ? "nav__item active1" : "nav__item"}>
            <Link
              style={{ cursor: "pointer" }}
              activeClass="active"
              to="portfolio"
              spy={true}
              smooth={true}
              offset={-70}
              duration={500}
              className="nav__link"
              onClick={navToggle} // Close the menu when a link is clicked
            >
              Our Work
            </Link>
          </li> */}

          <li className={navbar ? "nav__item active1" : "nav__item"}>
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/career'

          >
    Career
  </Li>
</li>
          <li className={navbar ? "nav__item active1" : "nav__item"}>
          <Li className="nav__link"
              style={{ cursor: "pointer" }}
              onClick={navToggle}
           to='/developing'

          >
    Solutions
  </Li>
          </li>

          <Button
  variant="contained"

  sx={{
    cursor: "pointer",
    borderRadius: "25px",
    fontSize: "1.1rem",
    paddingTop: "0.5rem",
    paddingBottom: "0.5rem",
    background: "linear-gradient( 150deg , #40b298 , #261141)",

    "@media(max-width:480px)": {
      backgroundColor: "#40b298",
    },
    transition: "transform 0.5s ease", // Added transition for smooth movement
    "&:hover": {
      transform: "translateY(-1px) scale(1.05)", // Move up and slightly increase size on hover

    },
  }}

>
  <Link to="contactSection" spy={true} smooth={true} offset={-70} duration={500}
   onClick={navToggle}
  >
    Contact us
  </Link>
</Button>


          {/* Add more links for other sections */}
        </ul>


        <div onClick={navToggle} className={icon}>
          <div className="line1"></div>
          <div className="line2"></div>
          <div className="line3"></div>
        </div>


      </nav>


    </>
  );
}

export default Header;

import { Box, Button, Grid, Typography } from "@mui/material";
import React from "react";
import { Link } from "react-router-dom";
import Joinus from "../../Assets/Images/Joinusnew.svg";

const JoinUs = () => {
  return (
    <>
      <Box

        sx={{

display:'flex',
justifyContent:'center',
alignItems:'center',
          minHeight: "30rem",


          "@media(max-width:600px)": {
            minHeight: "40rem",
            display:'none'
          },
          "@media(min-width:601px) and (max-width:768px)": {
            minHeight: "25rem",


          },
          "@media(min-width:769px) and (max-width:1024px)": {
            minHeight: "25rem",
          },
        }}
      >
        <Grid container paddingLeft={"10%"} paddingRight={"10%"}>
          <Grid item lg={6} md={6} sm={6} xs={12}
          data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1500"

          >
            <div
              class="afterglowcard"
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "auto",
              }}
            >
              <img
                src={Joinus}
                height={"100%"}
                width={"100%"}
                alt="Dedicated Team"
              />
            </div>
          </Grid>

          <Grid
            item
            lg={6}
            md={6}
            sm={6}
            xs={12}
            sx={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",

            }}
          >

             <div>
             <Typography
                variant="h1"
                sx={{
                  fontSize: "2rem",
                  paddingLeft: "2rem",
                  color: "#261141",


                  "@media(min-width:481px) and (max-width:768px)": {
                    fontSize: "1.8rem",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "2rem",
                    paddingLeft: "10%",
                    paddingRight: "10%",
                  },
                }}
              >
               <span style={{color:'#40b298'}}>Take your </span>career to the next level{" "}
              </Typography>
              <Typography
                sx={{
                  padding: "2rem",
                  fontSize: "1rem",
color:'#595959',
                  "@media(max-width:480px)":{
padding:'0rem',
paddingTop:'1rem'
                  },
                  "@media(min-width:481px) and (max-width:768px)": {
                    fontSize: "0.8rem",
                    paddingTop: "1rem",
                    paddingLeft: "10%",
                  },
                  "@media(min-width:769px) and (max-width:1024px)": {
                    fontSize: "0.8rem",
                    paddingLeft: "10%",
                  },
                }}
              >
                AIX Consulting is one of the most sought-after employers
                in the industry. Apply now and become a part of our
                family
              </Typography>
              <Link to={"/career"}>
                <Button
                  variant="outlined"
                  sx={{
                    marginLeft: "2rem",
                    borderRadius: "25px",
                    fontSize: "1.1rem",

                    color: "black",

                    borderColor: "#261141",
                    "@media(max-width:480px)":{
marginLeft:'0rem',
marginTop:'1rem'
                  },
                    "@media(min-width:481px) and (max-width:768px)": {
                      fontSize: "0.8rem",
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                      fontSize: "0.8rem",
                    },
                    "&:hover": {
                borderColor: "#40b298",
                backgroundColor: "#40b298",
                color:'white',
                outline: "none",
              },
                  }}
                >
                  Join us &rarr;
                </Button>
              </Link>

             </div>
          </Grid>
        </Grid>
      </Box>
    </>
  );
};

export default JoinUs;

import React from "react";
import HeroSection from "../../Layouts/HeroSection";
import OurServices from "./OurServices";
import Expertise from "./Expertise";
import CoreValues from "./CoreValues";
import ContactForm from "./ContactForm";
import Footer from "../../Layouts/Footer";
import OurWork from "./OurWork";
import JoinUs from "./JoinUs";
import Header from "../../Layouts/Header";
import DedicatedTeam from "./DedicatedTeam";
import MobJoinUs from "./MobJoinUs";
const Home = () => {
  return (
    <>
    <Header/>

      <HeroSection />

      <OurServices />
      <CoreValues />

      <DedicatedTeam/>

      {/* <OurWork /> */}
      {/* <Expertise /> */}
      <JoinUs/>
      <MobJoinUs/>
      <ContactForm />
      <Footer />
    </>
  );
};

export default Home;

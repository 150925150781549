import { Button, Box, Grid, Typography } from "@mui/material";
import React from "react";
import Team from "../../Assets/Images/Teradata.svg";


const DedicatedTeam = () => {

  return (
    <>
      <Box
        sx={{
          background: "white",

          "@media(max-width:480px)": {
            paddingTop: "1rem",
          },
          "@media(min-width:481px) and (max-width:768px)": {
                     paddingTop:'3rem'
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                     paddingTop:'3rem'
                    },
        }}
      >
        <Box
          sx={{
            paddingLeft: "10%",
            paddingRight: "10%",
            minHeight: "10rem",
            paddingBottom: "3rem",
            color: "#261141",
            "@media(max-width:480px)": {
              paddingTop: "4rem",
            },
          }}
        >
          <Grid container alignItems={"center"}>
            <Grid item lg={6} md={6} sm={6} xs={12}>
              <Box
               data-aos="fade-up"


data-aos-duration="1500"
                width={"85%"}
                sx={{
                  "@media(max-width:480px)": {
                    width: "100%",
                  },
                }}
              >
                <Typography
                  variant="h1"

                  sx={{
                    fontWeight: "500",
                    fontSize: "2rem",

                    "@media(max-width:480px)": {
                      fontSize: "1.5rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1.5rem",
                    },
                    "@media(min-width:769px) and (max-width:1024px)": {
                      fontSize: "1.5rem",
                    },
                  }}
                >
                 <span style={{color:'#40b298'}}>Revolutionizing your business </span> with cutting edge software
                  solution
                </Typography>
                <br />
                <Typography
                  fontSize={"1.2rem"}
                  sx={{
                    "@media(max-width:480px)": {
                      fontSize: "1.2rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1.2rem",
                    },
                    color: "black",
                  }}
                >
                  <ul style={{ paddingLeft: "20px" }}>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>
                      <Typography sx={{fontSize:'1.3rem', color:'#595959',
                        "@media(max-width:480px)": {
                    fontSize: "1rem",

                  },
                      }}>Power AI innovation</Typography>
                    </li>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>

                      <Typography sx={{fontSize:'1.3rem', color:'#595959',
                        "@media(max-width:480px)": {
                    fontSize: "1rem",

                  },
                      }}>Enjoy easier Collaboration</Typography>
                    </li>
                    <li style={{ position: "relative" }}>
                      <span
                        style={{
                          color: "#40b298",
                          position: "absolute",
                          left: "-20px",
                        }}
                      >
                        &bull;
                      </span>

                      <Typography sx={{fontSize:'1.3rem', color:'#595959',
                       "@media(max-width:480px)": {
                    fontSize: "1rem",

                  },

                      }}>Develop solutions faster</Typography>
                    </li>
                  </ul>
                </Typography>
                <br />
                <br />
                <Button
                  type="submit"
                  variant="outlined"
                  sx={{
backgroundColor:'#eef0f3',

                    alignSelf: "center",
                    fontSize: "1.1rem",
                    paddingTop: "0.5rem",
                    paddingBottom: "0.5rem",
                    borderRadius: "25px",

                    color: "#261141",
                    transition: "transform 0.5s ease", // Added transition for smooth movement
    "&:hover": {
      transform: "translateY(-5px) scale(1.05)", // Move up and slightly increase size on hover
backgroundColor:'#40b298',
color:'white',
borderColor:'#40b298'
    },
                    "@media(max-width:480px)": {
                      fontSize: "1rem",
                    },
                    "@media(min-width:480px) and (max-width:768px)": {
                      fontSize: "1rem",
                    },
                  }}
                >
                  Empower Your business &rarr;
                </Button>
              </Box>
            </Grid>
            <Grid
             data-aos="flip-left"
     data-aos-easing="ease-out-cubic"
     data-aos-duration="1500"
            padding='3rem'
              item
              lg={6}
              md={6}
              sm={6}
              xs={12}
              zIndex={"100"}
              sx={{
                "@media(max-width:480px)": {
                  marginTop: "2rem",
                  padding:'0rem'
                },
              }}
            >
              <div
                class="afterglow"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "auto",
                }}

              >

                <img

                  src={Team}
                  height={"100%"}
                  width={"100%"}
                  alt="Dedicated Team"
                />
              </div>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
};

export default DedicatedTeam;

import { Box, Typography } from "@mui/material";
import React from "react";

const ServicesCard = (props) => {
  const boxStyle = {
    backgroundColor: "white",
    minHeight: "25rem",
    padding: "2rem 1rem 1rem 1rem",
    transition: "background-color 0.5s ease",
    boxShadow: "0px 4px 10px gray",
    color: "#595959",
    "&:hover": {
      backgroundColor: "#40b298",
      color: "white",
      "& h1": {
        color: "white", // Heading color on hover
      },
      "& subtitle": {
        color: "white", // Heading color on hover
      },
    },
    "@media(max-width:480px)": {
      minHeight: "18rem",
    },
    "@media(min-width:481px) and (max-width:768px)": {
      minHeight: "20rem",
    },
    "@media(min-width:769px) and (max-width:1024px)": {
      minHeight: "21rem",
    },
  };


  const headingStyle = {
    color: "#261141",

    fontSize: "1.5rem",
    fontWeight: 550,
    paddingTop: "1rem",
    "@media (max-width:480px)": {
      fontSize: "1.2rem",
    },
    "@media(min-width:769px) and (max-width:1024px)": {
      fontSize: "1rem",
    },
  };

  return (
    <>
      <Box sx={boxStyle}>
        <div>{props.icon}</div>

        <Typography variant="h1" sx={headingStyle}>
          {props.heading}
        </Typography>

        <Typography
          sx={{
            paddingTop: "1rem",
            "@media(max-width:480px)": {
              fontSize: "0.9rem",
            },
            "@media(min-width:769px) and (max-width:1024px)": {
              fontSize: "0.7rem",
            },
          }}
        >
          {props.paragraph}
        </Typography>
      </Box>
    </>
  );
};

export default ServicesCard;

import { Box, Button, Grid, Typography } from "@mui/material";
import React, { useState } from "react";
import ApplyFormModal from "../Career/ApplyFormModal";

const OpenJobs = () => {
  // State variables and functions for each job description
  const [showMore1, setShowMore1] = useState(false);

  const text1 = `
  <span style="font-size: 0.9rem;">
  We are seeking a dynamic and results-oriented Senior Sales Manager to join us. The ideal candidate will have a minimum of 3 years of proven experience in sales, particularly within the software industry. As the Senior Sales Manager, you will be responsible for leading a high-performing sales team, driving revenue growth, and fostering strong client relationships.
  </span>
  <br />
  <span style="font-size: 0.9rem; font-weight:650">
  Requirements:<br />
</span>
<span style="font-size: 0.8rem;">

  -Bachelor’s degree in Business, Marketing, or a related field.<br />
  - Minimum of 3 years of experience in software sales, with a track record of meeting or exceeding sales targets.<br />
  - Proven experience in team leadership and management.<br />
  - Excellent English communication skills, both written and spoken.<br />
  - Strong negotiation and interpersonal skills.<br />
  - Familiarity with CRM software and sales analytics tools.<br />
  - Develop and implement effective sales strategies to achieve revenue targets.<br />
  - Collaborate with the executive team to align sales initiatives with overall company goals.<br />
  - Lead, mentor, and motivate a team of sales professionals to achieve individual and team targets.


  </span>
`;


  const truncatedText1 = showMore1 ? text1 : text1.slice(0, 200) + "...";


  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <Box
        sx={{
          minHeight: "30rem",
          paddingLeft: "10%",
          paddingRight: "10%",
          paddingBottom: "5rem",
          backgroundColor: "white",

          "@media(min-width:481px) and (max-width:768px)": {
            paddingBottom: "2rem",
            minHeight: "50vh",
          },
        }}
      >
        <Box sx={{ width: "100%", paddingTop: "3rem", paddingBottom: "3rem" }}>
          <Typography
            variant="h2"
            sx={{
              fontWeight: "600",
              fontSize: "1.5rem",
              color: "#261141",
              "@media(max-width:480px)": {
                fontSize: "1.2rem",
              },
            }}
            className="line"
          >
            Open Vacancies
          </Typography>
        </Box>



{/* --------------------------------------Sr. Sales Manager------------- */}
        <Grid
          data-aos="fade-up"


data-aos-duration="1500"
          container
          spacing={3}
          minHeight="20vh"
          display="flex"
          justifyContent={"center"}
          alignItems={"flex-start"}
          padding={"2rem"}
          sx={{
            "@media(max-width:600px)": {
              justifyContent: "flex-start",
              paddingLeft: "0rem",
            },
          }}
        >


          <Grid item lg={4} md={4} sm={12} sx={12}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "700" }}
            >
             Senior Sales Manager
            </Typography>

            <Typography sx={{ fontSize: "0.9rem" }}>Dec 1, 2023</Typography>
          </Grid>

          <Grid item lg={6} md={6} sm={12} sx={12} width={"100%"}>
            <Typography
              variant="h1"
              sx={{ fontSize: "1.3rem", color: "#261141", fontWeight: "600" }}
            >
              Description
            </Typography>

            <div>
              <Typography
                sx={{ fontSize: "1rem" }}
                dangerouslySetInnerHTML={{ __html: showMore1 ? text1 : truncatedText1 }}
              ></Typography>
              <button
                style={{
                  background: "none",
                  border: "none",
                  color: "gray",
                  cursor: "pointer",
                }}
                onClick={() => setShowMore1(!showMore1)}
              >
                {showMore1 ? "See Less" : "See More"}
              </button>
            </div>
          </Grid>

          <Grid item lg={2} md={2} sm={12} sx={12}>
            <a href="https://forms.gle/jTxSwHuKDGfPvMuUA" target="_blank" rel="noopener noreferrer">
              <Button
                variant="contained"
                sx={{
                  fontSize: "1rem",
                  borderRadius: "25px",
                  background: "linear-gradient( 150deg , #40b298 , #261141)",


                  paddingTop: "0.5rem",
                  paddingBottom: "0.5rem",
                  '@media (min-width:481px) and (max-width:1024px)': {
                    fontSize: '0.8rem'
                  }
                }}
              >
                Apply Now
              </Button>
            </a>
          </Grid>
        </Grid>

        <hr />



        <ApplyFormModal isOpen={isModalOpen} onRequestClose={closeModal} />
      </Box>
    </>
  );
};

export default OpenJobs;

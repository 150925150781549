import "./App.css";
import React, { useState, useEffect } from "react";
import Career from "./Pages/Career/Career";
import Home from "./Pages/Home/Home";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {  Routes, Route } from "react-router-dom";
import ScrollToTop from "./ScrollToTop";
import JobApplyForm from "./Pages/Career/ApplyFormModal";
import { ToastContainer } from "react-toastify";
import CookieConsent from "react-cookie-consent";
import "react-toastify/dist/ReactToastify.css";
import { styled } from "@mui/material/styles";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";

import { Box, FormControlLabel, Switch, Typography } from "@mui/material";
import UnderDevelop from "./Pages/UnderDevelop";
const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

function App() {
  const theme = createTheme({
    palette: {
      primary: {
        main: "#261141",
      },
    },
    typography: {
      allVariants: {
        fontFamily: "Arial",

      },
      button: {
        textTransform: "none",

      },
    },
  });

  const [showCookieConsent, setShowCookieConsent] = useState(false);
  const [cookiesAccepted, setcookiesAccepted] = useState(false);
  useEffect(() => {
    const storedCookiesAccepted = localStorage.getItem("cookiesAccepted");
    const storedCookiesExpiration = localStorage.getItem("cookiesExpiration");

    // Check if the stored information is valid and not expired
    if (
      storedCookiesAccepted === "true" &&
      storedCookiesExpiration &&
      new Date(storedCookiesExpiration) > new Date()
    ) {
      setcookiesAccepted(true);
    } else {
      setShowCookieConsent(true);
    }
  }, []);

  const handleAcceptAll = () => {
    setShowCookieConsent(false);
    setcookiesAccepted(true);

    // Set an expiration date for the stored information (one day from now)
    const expirationDate = new Date();
    expirationDate.setDate(expirationDate.getDate() + 1);

    localStorage.setItem("cookiesAccepted", "true");
    localStorage.setItem("cookiesExpiration", expirationDate.toISOString());
  };
  const handleCustomize = () => {
    setShowCookieConsent(false);
    setcookiesAccepted(true);
    localStorage.setItem("cookiesAccepted", "true");
  };
  const [open, setOpen] = React.useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };
  const Close = () => {
    handleCustomize();
    handleClose();
    handleAcceptAll();
  };
  
  return (
    <>
      <ThemeProvider theme={theme}>

          <ScrollToTop />
          <Routes>
            <Route path="/" element={<Home />} exact />
            <Route path="/career" element={<Career />}/>
            <Route path="/career/apply" element={<JobApplyForm />}/>
            <Route path="/developing" element={<UnderDevelop />}/>
          </Routes>

      </ThemeProvider>

      {!cookiesAccepted && showCookieConsent && (
        <CookieConsent
         data-aos="fade-up"
data-aos-duration="3000"
          location="bottom"
          buttonText=""
          buttonStyle={{
            backgroundColor: "transparent",
            fontSize: "1rem",
            padding: "0rem 0rem",
            color: "white",
          }}
          style={{ background: "white", color: "black" }}
          expires={150}
        >
          <div className="cookies">
            <p style={{ width: "80%" }}>
              We use cookies on our website to give you the most relevant
              experience by remembering your preferences and repeat visits. By
              clicking “Accept All”, you consent to the use of ALL the cookies.
              However, you may visit 'Cookie Settings' to provide a controlled
              consent.
            </p>
            <div style={{ display: "flex" }}>
              <div style={{ display: "flex" }} className="btn">
                <button
                  style={{
                    backgroundColor: "#61A229",
                    color: "white",
                    fontSize: "1rem",
                    padding: "0.7rem 1rem 0.7rem 1rem",
                    border: "none",
                    borderRadius: "2px",
                  }}
                  onClick={handleAcceptAll}
                >
                  Accept All
                </button>

                <button
                  style={{
                    backgroundColor: "#DEDFE0",
                    color: "black",
                    fontSize: "1rem",
                    padding: "0.7rem 1rem 0.7rem 1rem",
                    border: "none",
                    borderRadius: "2px",
                    marginLeft: "1rem",
                  }}
                  onClick={handleClickOpen}
                >
                  Customize
                </button>

                <ThemeProvider theme={theme}>
                  <BootstrapDialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                  >
                    <DialogTitle
                      sx={{
                        m: 0,
                        p: 2,
                        "@media(max-width:600px)": { fontSize: "1rem" },
                      }}
                      id="customized-dialog-title"
                    >
                      Cookies Customization
                    </DialogTitle>
                    <IconButton
                      aria-label="close"
                      onClick={Close}
                      sx={{
                        position: "absolute",
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                    <DialogContent>
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "1rem",
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          This website uses cookies to improve your experience
                          while you navigate through the website. Out of these,
                          the cookies that are categorized as necessary are
                          stored on your browser as they are essential for the
                          working of basic functionalities of the website. We
                          also use third-party cookies that help us analyze and
                          understand how you use this website. These cookies
                          will be stored in your browser only with your consent.
                        </Typography>
                      </Box>

                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Necessary Cookies
                        </Typography>
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Always Enable
                        </Typography>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                          marginTop: "5px",
                          "@media(max-width:600px)": {
                            padding: "0rem 0rem 0rem 0.5rem",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Functional Cookies
                        </Typography>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                        />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                          marginTop: "5px",
                          "@media(max-width:600px)": {
                            padding: "0rem 0rem 0rem 0.5rem",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Performance Cookies
                        </Typography>
                        <FormControlLabel control={<Switch />} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                          marginTop: "5px",
                          "@media(max-width:600px)": {
                            padding: "0rem 0rem 0rem 0.5rem",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Advertisement Cookies
                        </Typography>
                        <FormControlLabel control={<Switch />} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                          marginTop: "5px",
                          "@media(max-width:600px)": {
                            padding: "0rem 0rem 0rem 0.5rem",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Social Networking Cookies
                        </Typography>
                        <FormControlLabel control={<Switch />} />
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          backgroundColor: "#f2f2f2",
                          padding: "1rem 0rem 1rem 0.5rem",
                          borderRadius: "3px",
                          marginTop: "5px",
                          "@media(max-width:600px)": {
                            padding: "0rem 0rem 0rem 0.5rem",
                          },
                        }}
                      >
                        <Typography
                          sx={{
                            "@media(max-width:600px)": { fontSize: "0.8rem" },
                          }}
                        >
                          Other Cookies
                        </Typography>
                        <FormControlLabel
                          control={<Switch color="primary" />}
                        />
                      </Box>
                    </DialogContent>
                    <DialogActions>
                      <Button
                        variant="contained"
                        sx={{ backgroundColor: "#261141", color: "white" }}
                        onClick={Close}
                      >
                        {" "}
                        Save & Accept
                      </Button>
                    </DialogActions>
                  </BootstrapDialog>
                </ThemeProvider>
              </div>
            </div>
          </div>
        </CookieConsent>
      )}

      <ToastContainer />
    </>
  );
}

export default App;
